<template>
    <div class="lv_flex">
        <div class="menu">
            <el-menu :router="true">
                <el-submenu index="flower">
                    <template slot="title">
                        <span>花材</span>
                    </template>
                    <el-menu-item index="/flower/list">列表</el-menu-item>
                    <el-menu-item index="/flower/detail">
                        添加/编辑
                    </el-menu-item>
                </el-submenu>
                <el-submenu index="container">
                    <template slot="title">
                        <span>器皿</span>
                    </template>
                    <el-menu-item index="/common/list">列表</el-menu-item>
                    <el-menu-item index="/container/detail">
                        添加/编辑
                    </el-menu-item>
                </el-submenu>
                <el-submenu index="jianshan">
                    <template slot="title">
                        <span>剑山</span>
                    </template>
                    <el-menu-item index="/common/list?type=3">
                        列表
                    </el-menu-item>
                    <el-menu-item index="/jianshan/detail">
                        添加/编辑
                    </el-menu-item>
                </el-submenu>
                <el-submenu index="user">
                    <template slot="title">
                        <span>用户管理</span>
                    </template>
                    <el-menu-item index="/user/free">免费体验用户</el-menu-item>
                    <el-menu-item index="/user/vip">正式客户列表</el-menu-item>
                    <el-menu-item index="/user/all">全部用户</el-menu-item>
                </el-submenu>
                <el-menu-item index="/label">
                    <span slot="title">标签管理</span>
                </el-menu-item>
                <el-menu-item index="/templateFlower">
                    <span slot="title">首页显示模板管理</span>
                </el-menu-item>
                <el-menu-item index="/AIManage">
                    <span slot="title">AI管理</span>
                </el-menu-item>
                <el-submenu index="version">
                    <template slot="title">
                        <span>版本管理</span>
                    </template>
                    <el-menu-item index="/version/list">列表</el-menu-item>
                </el-submenu>
                <el-submenu index="courseware">
                    <template slot="title">
                        <span>课件管理</span>
                    </template>
                    <el-menu-item index="/courseware/list">列表</el-menu-item>
                </el-submenu>
            </el-menu>
            <div @click="logout">登出</div>
        </div>
        <div class="lv_flex_1 main">
            <router-view />
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        logout () {
            this.$ajax.post("/login/logout").then(() => {
                this.$router.push("/login");
            });
        },
    },
};
</script>
<style>
.lv_flex {
    display: flex;
}

.lv_flex_1 {
    flex: 1;
}

.lv_flexalign_center {
    display: flex;
    align-items: center;
}
</style>
<style lang="scss" scoped>
.main {
    padding: 15px;
    background: #fff;
}

.menu {
    width: 200px;
    height: 100vh;
    background: #fff;
    margin-right: 10px;
}
</style>
