import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '../views/layout.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: layout,
        redirect: '/flower/list',
        children: [
            {
                path: '/flower/list',
                component: () => import('../views/flower/list.vue'),
                name: 'flowerList'
            },
            {
                path: '/flower/detail',
                component: () => import('../views/flower/detail.vue'),
                name: 'flowerDetail'
            },
            {
                path: '/common/list',
                component: () => import('../views/container/list.vue'),
                name: 'commonList'
            },
            {
                path: '/container/detail',
                component: () => import('../views/container/detail.vue'),
                name: 'containerDetail'
            },
            {
                path: '/jianshan/detail',
                component: () => import('../views/jianshan/detail.vue'),
                name: 'jianshanDetail'
            },
            {
                path: '/version/list',
                component: () => import('../views/version/list.vue'),
                name: 'versionList'
            },
            {
                path: '/user/free',
                component: () => import('../views/user/free.vue'),
                name: 'userFree'
            },
            {
                path: '/user/vip',
                component: () => import('../views/user/vip.vue'),
                name: 'userVip'
            },
            {
                path: '/user/all',
                component: () => import('../views/user/all.vue'),
                name: 'userAll'
            },
            {
                path: '/label',
                component: () => import('../views/label.vue'),
                name: 'label'
            },
            {
                path: '/templateFlower',
                component: () => import('../views/templateFlower/index.vue'),
                name: 'templateFlower'
            },
            {
                path: '/AIManage',
                component: () => import('../views/AIManage/index.vue'),
                name: 'AIManage'
            },
            {
                path: '/courseware/list',
                component: () => import('../views/courseware/list.vue'),
                name: 'coursewareList'
            }],

    },

    {
        path: '/login',
        component: () => import('../views/login.vue'),
        name: 'login'
    }
]

const router = new VueRouter({
    routes
})

export default router
