import axios from 'axios';
import { Message } from 'element-ui';
let base = 'https://api.lvshuhualv.com';
let isDev = process.env.NODE_ENV === 'development';
if (isDev) {
    // base = "https://api.lvshuhualv.com";
    // base = 'http://218.66.91.50:7991';
    // base = "https://ikuai0.haloom.cc:7991"
}
let ajax = axios.create({
    baseURL: base,
    timeout: 122260000,
    withCredentials: true
});

// 添加请求拦截器
ajax.interceptors.request.use(
    function (config) {

        !config.headers.authToken &&
            Object.assign(config.headers, {
                authToken: localStorage.getItem('authToken'),
            });

        return config;
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 添加响应拦截器
ajax.interceptors.response.use(
    (res) => {
        // 对响应数据做点什么
        const code = res.data.code || 200;
        if (code == 401) {
            window.location.href =
                process.env.NODE_ENV === 'development'
                    ? 'http://192.168.31.226:8080/#/login'
                    : 'https://oa.lvshuhualv.com/#/login';
            Message({
                type: 'error',
                message: res.data.msg
            });
        } else if (code != 200) {

            if (code == 403) {
                return Promise.reject(res.data);
            }
            if (code == 2001) {
                return res.data;
            }
            Message({
                type: 'error',
                message: res.data.message
            });
            return Promise.reject(res.data);
        } else {
            return res.data;
        }
    },
    (error) => {
        let { message, code } = error;

        if (message == 'Network Error') {
            message = '接口连接异常';
        } else if (message.includes('timeout') && code == 'ECONNABORTED') {
            message = '接口请求超时';
        } else if (message == 'Request failed with status code 403') {
            return Promise.reject(error);
        } else if (message.includes('Request failed with status code')) {
            message = '接口' + message.substr(message.length - 3) + '异常';
        }
        Message({
            message: message,
            type: 'error',
            duration: 5 * 200
        });
        return Promise.reject(error);
    }
);

const install = function (Vue) {
    Vue.prototype.$ajax = ajax;
};

export default {
    install
};